<template>
  <b-card>
      <div>
        <b-card-text>
          <h3>Lynch Criterias</h3>
        </b-card-text>
          <div class="row">
            <div class="col-md-6">
              <label  class="mr-1 ml-1 text-muted"
                for="demo-sb">3y Revenue growth:</label>
                  <b-form-spinbutton
                    disabled
                    class="mb-5"
                    id="demo-sb"
                    v-model="growth"
                    min="1"
                    max="100"/>
            </div>
            <div class="col-md-6">
              <label  class="mr-1 ml-1 text-muted"
                for="demo-sb">Current Ratio:</label>
                  <b-form-spinbutton
                    disabled
                    id="demo-sb"
                    v-model="CurrentRatio"
                    min="1"
                    max="100"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label  class="mr-1 ml-1 text-muted"
                for="demo-sb">PEG-Ratio:</label>
                  <b-form-spinbutton
                    disabled
                    id="demo-sb"
                    v-model="PEGRATIO"
                    min="1"
                    max="100"/>
            </div>
            <div class="col-md-6 mb-4">
              <label  class="mr-1 ml-1 text-muted"
                for="demo-sb">Market Capitalization:</label>
                  <b-form-spinbutton
                    wrap
                    disabled
                    :formatter-fn="MarketCapformatter"
                    id="demo-sb"
                    v-model="MarketCap"
                    min="0"
                    max="100"/>
            </div>
          </div>
        <b-row class="mt-4">
              <p class="card-text"><small class="text-muted">These Values are provided by Peter Lynch in his book Beating The Street. Keep in mind that this Screener <h6>DOES NOT REPLACE INVESTIAGTING IN YOUR OWN RESEARCH</h6></small></p>
      </b-row>
      </div>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BFormSpinbutton, BAlert, BCardText } from 'bootstrap-vue'
import { codeBasic } from './code'

export default {
  components: {
    BFormSpinbutton,
    BCardCode,
    BCardText,
    BAlert,
  },
  data() {
    return {
      value: 50,
      growth: 20,
      Current: 10,
      PEGRATIO: 1,
      CurrentRatio: 1.5,
      margin: 10,
      codeBasic,
      MarketCap: 0,
      MarketCaps: ['1 Billion'],
    }
  },
  methods: {
    MarketCapformatter(value) {
      return this.MarketCaps[value]
    },
  },
}
</script>

<style>
.b-form-spinbutton {
  border-radius: 15px; /* Set the border radius to 15px */
}
</style>